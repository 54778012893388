import KlaviyoFunctions from '@analytics/klaviyo/functions';
import Mixpanel from '@analytics/mixpanel/Mixpanel';
import {
  OnFooterLinkSelectedInput,
  OnFooterSocialButtonClickedInput,
  OnKeepAliveInput,
  OnNavigationLanguageSwitchInput,
  OnYelaLogoClickedInput,
} from '@analytics/mixpanel/types/General.types';
import { BaseInput } from '@analytics/mixpanel/types/Mixpanel.types';
import { getUserData } from '@common/utils';
import Routes from '@constants/routes';

export const onAppInit = ({ router }: BaseInput): void => {
  if (router.pathname == Routes.SOCIAL_FACEBOOK_AUTH) return;
  const userAuthData = getUserData(document.cookie);
  if (userAuthData) Mixpanel.identify(userAuthData.id);
  KlaviyoFunctions.identify();
  Mixpanel.track(`Beta Product Session Start`, Mixpanel.createEventProperties({ router }));
};

export const onAppClose = ({ router }: BaseInput): void => {
  if (router.pathname == Routes.SOCIAL_FACEBOOK_AUTH) return;
  Mixpanel.track(`Beta Product Session End`, Mixpanel.createEventProperties({ router }));
};

export const onNavigationLanguageSwitch = ({ router, newLanguage }: OnNavigationLanguageSwitchInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Navigation Language Switch`);
  Mixpanel.track(
    `Navigation Language Switch`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'New Language': newLanguage,
      },
    }),
  );
};

export const onFooterSocialButtonClicked = ({ router, button }: OnFooterSocialButtonClickedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Footer Social Button Selected`);
  Mixpanel.track(
    `Footer Social Button Selected`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Social Button': button,
      },
    }),
  );
};

export const onFooterLinkSelected = ({ router, link }: OnFooterLinkSelectedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Footer Link Selected`);
  Mixpanel.track(
    `Footer Link Selected`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Link: link,
      },
    }),
  );
};

export const onUserProfileClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `User Profile Clicked`);
  Mixpanel.track(
    `User Profile Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onLogoutButtonClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Log Out Button Clicked`);
  Mixpanel.track(
    `Log Out Button Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onYelaLogoClicked = ({ router, position }: OnYelaLogoClickedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Yela Logo Clicked`);
  Mixpanel.track(
    `Yela Logo Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Logo Position': position,
      },
    }),
  );
};

export const onKeepAlive = ({ router, clicked, scrolled, touchStarted }: OnKeepAliveInput): void => {
  Mixpanel.track(
    `Keep Alive`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Clicked: clicked,
        Scrolled: scrolled,
        TouchStarted: touchStarted,
      },
    }),
  );
};

export const onCookiesAccepted = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Cookies Accepted`);
  Mixpanel.track(
    `Cookies Accepted`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onCookiesImproveLinkClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Cookies Improve Link Clicked`);
  Mixpanel.track(
    `Cookies Improve Link Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onCookiesPolicyPageLoaded = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Cookies Policy Page Loaded`);
  Mixpanel.track(
    `Cookies Policy Page Loaded`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};
