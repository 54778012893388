import { LogInResponse } from '@api/Auth';
import Routes from '@constants/routes';
import cookie from 'cookie';
import { Dispatch, SetStateAction } from 'react';

export const getUserData = (dataCookie: string | undefined): LogInResponse | null => {
  try {
    const parsedCookie = cookie.parse(dataCookie || '');
    let user = null;
    if (parsedCookie && parsedCookie.userData) {
      user = JSON.parse(parsedCookie.userData);
    }
    return user;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const setUserData = (userData: LogInResponse): void => {
  document.cookie = 'userData=' + JSON.stringify({ ...userData, name: encodeURI(userData.name) }) + '; path=/; max-age=31536000';
};

export const setUserCountry = (userCountry: string | undefined): void => {
  if (typeof userCountry !== 'string') return;
  if (typeof document === 'undefined') return;
  document.cookie = 'userCountry=' + userCountry + '; path=/; max-age=31536000';
};

export const getUserCountry = (): string | undefined => {
  const { userCountry } = cookie.parse(document.cookie);
  if (typeof userCountry !== 'string') return undefined;
  return userCountry;
};

export const invalidateUserData = (): void => {
  localStorage.clear();
  document.cookie = 'userData= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};

interface ValidateUserInterface {
  dataCookie: string | undefined;
  isAuthPage: boolean;
}

export const validateUser = ({ dataCookie, isAuthPage }: ValidateUserInterface): any => {
  const user = getUserData(dataCookie);

  if (isAuthPage && user) {
    return {
      redirect: {
        destination: Routes.HOME,
        permanent: false,
      },
    };
  }

  if (!isAuthPage && !user) {
    return {
      redirect: {
        destination: Routes.LOGIN,
        permanent: false,
      },
    };
  }

  return {
    props: { user },
  };
};

interface SetFieldDataInterface<type> {
  value: string | any;
  name: string;
  setData: Dispatch<SetStateAction<type>>;
  data: type;
}

export const setFieldData = <type>({ value, name, setData, data }: SetFieldDataInterface<type>): void => {
  setData({ ...data, [name]: value });
};

export const downloadFile = (data: Blob, filename: string): void => {
  const blobData = [data];
  const blob = new Blob(blobData, { type: 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL && window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

interface FormatPriceInterface {
  currencyCode: string;
  currencySymbol: string;
  value: number;
  flip?: boolean;
  arabicCharacterSpecificFlip?: boolean;
}

export const formatPrice = ({ currencyCode, currencySymbol, value, flip, arabicCharacterSpecificFlip }: FormatPriceInterface): string => {
  let priceTag = currencySymbol;
  const valueString = value?.toLocaleString('en-US');
  const arabicCurrencyCodes = ['AED', 'EGP', 'SAR'];

  if (arabicCurrencyCodes.indexOf(currencyCode) > -1) {
    flip || arabicCharacterSpecificFlip ? (priceTag = valueString + ' ' + priceTag) : (priceTag = priceTag + ' ' + valueString);
  } else {
    flip ? (priceTag = valueString + priceTag) : (priceTag = priceTag + valueString);
  }

  return priceTag;
};

export const getLoggedInStatus = (): string => {
  return getUserData(document.cookie) ? 'Logged In' : 'Not Logged In';
};
