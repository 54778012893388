import { getUserData } from '@common/utils';

interface AddEventDetails {
  'Message Category'?: string;
  'Message Ownership Status'?: string;
  'Message Type'?: string;
  'Celebrity Name'?: string;
  'Reviewed Fields'?: string[];
  Price?: string;
}
interface AddEventDetailsOutput extends AddEventDetails {
  Language: string;
}

export default {
  identify: (email?: string): void => {
    if (!process.env.NEXT_PUBLIC_KLAVIYO_TOKEN) return;
    const windowObj = window as any;
    let finalEmail = email;
    if (!finalEmail) {
      const user = getUserData(document.cookie);
      if (!user || !user.email) return;
      finalEmail = user.email;
    }
    if (windowObj._learnq) windowObj._learnq.push(['identify', { $email: finalEmail }]);
  },
  addEvent: (name: string, details?: AddEventDetails): void => {
    if (!process.env.NEXT_PUBLIC_KLAVIYO_TOKEN) return;
    if (!getUserData(document.cookie)) return;
    const windowObj = window as any;
    const locale = window.location.pathname.startsWith('/ar') ? 'ar' : 'en';
    let detailsOutput: AddEventDetailsOutput = {
      Language: locale,
    };

    if (details) {
      detailsOutput = { ...detailsOutput, ...details };
    }

    if (windowObj._learnq) windowObj._learnq.push(['track', name, detailsOutput]);
  },
};
