import { CreateEventPropertiesInput, CreateEventPropertiesOutput } from '@analytics/mixpanel/types/Mixpanel.types';
import { getUserData } from '@common/utils';
import isbot from 'isbot';
import mixpanel from 'mixpanel-browser';

const enabled = (): boolean => {
  const isBot = typeof window !== 'undefined' && isbot(navigator.userAgent);
  return process.env.NEXT_PUBLIC_MIXPANEL_TOKEN != null && !isBot;
};

export default {
  /////////////////////
  // Default Methods //
  /////////////////////

  init: (): void => {
    if (!enabled()) return;
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
      property_blacklist: ['$current_url'],
    });
  },
  identify: (id: string): void => {
    if (!enabled()) return;
    mixpanel.identify(id);
  },
  track: (name: string, properties: CreateEventPropertiesOutput): void => {
    if (!enabled()) return;
    mixpanel.track(name, properties);
  },
  people: {
    set: (name: string, value: string, bypass?: boolean): void => {
      if (!enabled()) return;
      if (!bypass) {
        const userData = getUserData(document.cookie);
        if (!userData) return;
      }
      mixpanel.people.set(name, value);
    },
    increment: (name: string): void => {
      if (!enabled()) return;
      const userData = getUserData(document.cookie);
      if (!userData) return;
      mixpanel.people.increment(name);
    },
  },
  reset: (): void => {
    if (!enabled()) return;
    mixpanel.reset();
  },

  ////////////////////
  // Custom Methods //
  ////////////////////

  createEventProperties: ({ router, additionalProperties }: CreateEventPropertiesInput): CreateEventPropertiesOutput => {
    const locale = window.location.pathname.startsWith('/ar') ? 'ar' : 'en';
    const bookingFlowGroup = localStorage.getItem('experiment-booking_flow_single_step_group');
    let properties: CreateEventPropertiesOutput = {
      Marketplace: 'Fan',
      Platform: `Web`,
      Language: locale,
      Route: router.asPath?.split('#')[0],
      'Event Date': new Date().toUTCString(),
    };

    if (bookingFlowGroup) properties = { ...properties, 'Booking Flow Experiment Group': bookingFlowGroup };

    if (additionalProperties) {
      properties = { ...properties, ...additionalProperties };
    }

    return properties;
  },
};
